import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Pricelist2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || "12"} height={props.height || "14"}  viewBox="0 0 12 14" >
        <path d="M2.11084 3.4V2.2C2.11084 1.88174 2.2279 1.57652 2.43628 1.35147C2.64465 1.12643 2.92727 1 3.22195 1H7.94417L10.9997 4.3V11.8C10.9997 12.1183 10.8827 12.4235 10.6743 12.6485C10.4659 12.8736 10.1833 13 9.88862 13H2.11084" stroke={props.stroke || "#404040"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.66748 1V4.6H11.0008" stroke={props.stroke || "#404040"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 5.80005H4.33333" stroke={props.stroke || "#404040"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 7.30005H4.33333" stroke={props.stroke || "#404040"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 8.79993L3.36111 11.1999" stroke={props.stroke || "#404040"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 8.79993H1.83333" stroke={props.stroke || "#404040"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.83252 8.80005C3.68446 8.80005 3.68446 5.80005 1.83252 5.80005" stroke={props.stroke || "#404040"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default Pricelist2;
